<template>
  <div :class="$style.item">
    <div :class="$style.head">
      <img :src="data.icon" />
      <div :class="$style.flex">
        <div :class="$style.name">
          {{ data.name }}
          <div v-if="data.is_open" :class="$style.tag">已开通</div>
        </div>
        <div :class="$style.provider">由 {{ data.provider }} 提供</div>
      </div>
    </div>
    <div :class="$style.summary">{{ data.summary }}</div>
    <div :class="$style.time">
      <div :class="$style.category">{{ data.category }}</div>
      <div>更新时间：{{ data.updated_time }}</div>
    </div>
    <div :class="$style.foot">
      <div :class="$style.price">{{ data.price }}</div>
      <i :class="$style.detail" class="ri-arrow-right-line" @click="onDetail()" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  methods: {
    onDetail() {
      this.$router.push({
        name: 'centerStoreDetail',
        query: {
          id: this.data.id,
        },
      })
    },
  },
}
</script>

<style lang="less" module>
.item {
  width: 100%;
  height: 306px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 20px;

  .head {
    display: flex;

    img {
      width: 50px;
      height: 50px;
      border-radius: 6px;
      margin-right: 20px;
    }

    .name {
      font-size: 16px;
      margin-bottom: 6px;
    }

    .provider {
      font-size: 12px;
      color: #5c5c5c;
    }

    .tag {
      float: right;
      width: 60px;
      height: 24px;
      font-size: 12px;
      text-align: center;
      border: 1px solid #eee;
      color: rgba(92, 92, 92, 100);
      background: #fafafa;
      border-radius: 100px;
      line-height: 24px;
    }

    .flex {
      flex: 1;
    }
  }

  .summary {
    width: 100%;
    height: 96px;
    font-size: 13px;
    margin-top: 20px;
    margin-bottom: 20px;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  .time {
    font-size: 12px;
    color: #5c5c5c;
    margin-bottom: 20px;

    .category {
      margin-bottom: 4px;
    }
  }

  .foot {
    display: flex;
    align-items: center;
    .price {
      color: @error-color;
      flex: 1;
      line-height: 30px;
    }

    i {
      font-size: 20px;
    }

    .detail {
      display: none;
    }
  }
}

.item:hover {
  .detail {
    display: inline-block;
  }
}
</style>
