<template>
  <div :class="$style.home">
    <a-carousel autoplay>
      <div v-for="item in swiper" :key="item.id" :class="$style.banner">
        <img :src="item.image" />
      </div>
    </a-carousel>
    <div :class="$style.box">
      <a-affix :offset-top="80">
        <div :class="$style.left">
          <div :class="[$style.leftBox, type === 'recommend' ? $style.active : null]" @click="onChoose('recommend')">
            <i class="ri-thumb-up-line" />
            推荐应用
          </div>
          <div :class="[$style.leftBox, type === 'all' ? $style.active : null]" @click="onChoose('all')">
            <i class="ri-apps-2-line" />
            全部应用
          </div>
          <p :class="$style.title">应用分类</p>
          <div
            v-for="item in list"
            :key="item.id"
            :class="[$style.leftBox, type === item.id ? $style.active : null]"
            @click="onChoose(item.id, item.name)"
          >
            <i :class="item.icon" />
            {{ item.name }}
          </div>
        </div>
      </a-affix>

      <div :class="$style.right">
        <a-row v-if="type === 'recommend'" :gutter="[20, 20]">
          <a-col
            v-for="item in data"
            :key="item.id"
            :sm="{ span: 12 }"
            :md="{ span: 12 }"
            :lg="{ span: 12 }"
            :xl="{ span: 8 }"
            :xxl="{ span: 6 }"
          >
            <Item :data="item" />
          </a-col>
        </a-row>
        <div v-if="type !== 'recommend' && type !== 'all'">
          <p :class="$style.title">
            <i class="ri-home-4-line" @click="onHome()" />
            {{ typeName }}
          </p>
          <a-row :gutter="[20, 20]">
            <a-col
              v-for="item in data"
              :key="item.id"
              :sm="{ span: 12 }"
              :md="{ span: 12 }"
              :lg="{ span: 12 }"
              :xl="{ span: 8 }"
              :xxl="{ span: 6 }"
            >
              <Item :data="item" />
            </a-col>
          </a-row>
        </div>

        <div v-if="type === 'all'">
          <div v-for="item in data" :key="item.id">
            <p :class="$style.title">
              {{ item.name }}
              <span :class="$style.more" @click="onMore(item)">
                查看更多
                <i class="ri-arrow-right-s-line" />
              </span>
            </p>
            <a-row :gutter="[20, 20]">
              <a-col
                v-for="child in item.app_list"
                :key="child.id"
                :sm="{ span: 12 }"
                :md="{ span: 12 }"
                :lg="{ span: 12 }"
                :xl="{ span: 8 }"
                :xxl="{ span: 6 }"
              >
                <Item :data="child" />
              </a-col>
            </a-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { centerStoreService } from '@/service'

import Item from './item.vue'

const service = new centerStoreService()

export default {
  name: 'Home',
  components: {
    Item,
  },
  data() {
    return {
      swiper: [],
      list: [],
      data: [],
      type: 'recommend', // recommend 推荐  all 所有 type 分类
      page: 1,
      pageSize: 10,
      category_id: null,
      typeName: '',
    }
  },
  created() {
    this.getBanner()
    this.getList()
    this.getLeft()
  },
  methods: {
    async getBanner() {
      try {
        const { data } = await service.getBanner()
        this.swiper = data
      } catch (e) {}
    },
    async getList() {
      try {
        const { data } = await service.getList({
          page: this.page,
          page_size: this.pageSize,
          is_recommend: this.type === 'recommend' ? 1 : 0,
          category_id: this.category_id,
        })
        this.data = data.record
        this.total = data.total
      } catch (e) {}
    },
    async getAll() {
      try {
        const { data } = await service.getAll()
        this.data = data
      } catch (e) {}
    },
    async getLeft() {
      try {
        const { data } = await service.getLeft()
        this.list = data
      } catch (e) {}
    },
    onChoose(type, name) {
      this.type = type
      this.typeName = name
      if (type === 'all') {
        this.getAll()
      } else {
        this.getList()
      }
    },
    onHome() {
      this.type = 'all'
      this.getAll()
    },
    onMore(item) {
      this.onChoose(item.id, item.name)
    },
  },
}
</script>

<style lang="less" module>
.home {
  .banner {
    width: 100%;
    aspect-ratio: 1 / 0.211;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .box {
    display: flex;
    padding-top: 18px;
  }

  .left {
    width: 200px;

    .title {
      margin-top: 14px;
      margin-bottom: 10px;
      font-size: 12px;
      color: #5c5c5c;
    }

    &-box {
      width: 140px;
      height: 44px;
      line-height: 44px;
      border-radius: 6px;
      font-size: 14px;
      cursor: pointer;
      margin-bottom: 2px;

      i {
        font-size: 20px;
        color: #000;
        margin-left: 12px;
        margin-right: 8px;
        vertical-align: -4px;
      }
    }

    &-box:hover {
      background: #fff;
      color: @primary-color;

      i {
        color: @primary-color;
      }
    }

    .active {
      background: #fff;
      color: @primary-color;

      i {
        color: @primary-color;
      }
    }
  }

  .right {
    flex: 1;

    .title {
      font-size: 16px;

      i {
        font-size: 24px;
        vertical-align: -5px;
        margin-right: 10px;
      }

      .more {
        float: right;
        font-size: 12px;
        color: #5c5c5c;
        cursor: pointer;

        i {
          font-size: 18px;
          vertical-align: -4px;
        }
      }
    }
  }
}
</style>
